<template>
  <!-- eslint-disable -->
  <b-sidebar
    id="add-new-speciality"
    :visible="isAdd"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    no-close-on-backdrop
    @change="(val) => $emit('update:is-add', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Filtrar segun columnas</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <b-card-body class="">
        <b-card-text>
          <b-row>
            <b-col md="6" v-for="field in filtros" :key="field.id" class="justify-content-center">
              <b-checkbox
                :disabled="(visibleFields.length == 2 && field.visible) || field.key == 'actions'"
                v-model="field.visible"
                inline
                class="mb-1"
              >
                {{ field.label }}
              </b-checkbox>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ProjectsService from '@/services/ProjectsService'
import vSelect from 'vue-select'
import AreasService from '@/services/AreasService'
Vue.use(BootstrapVue)
export default {
  directives: {
    Ripple
  },
  components: {
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    filtros: {
      type: Array,
      required: true
    }
  },
  computed: {
    visibleFields() {
      return this.filtros.filter((field) => field.visible)
    }
  },
  data() {
    return {
      required,
      // speciality: {
      //   title: ''
      // },
      isEdit: false,
      isDisabled: false,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
